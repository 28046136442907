












































































































































































import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import Loader from "@/components/General/Loader.vue";
import DetailHeader from "@/components/Layout/Backoffice/DetailHeader.vue";
import { Notification } from "@/models/notification.interface";
import { CustomDate } from "@/mixins/custom-date";

@Component({
  components: {
    Loader,
    DetailHeader,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class KYCValidation extends mixins(Navigation, CustomDate) {
  loader = false;
  loadingA = false;
  loadingR = false;
  checkUserDialog = false;

  private async created(): Promise<void> {
    this.loader = true;

    await this.$store
      .dispatch("users/getUserById", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Users.fetchError.userById", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    await this.$store
      .dispatch("kyc/getUserKYC", this.$route.params.id)
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.ukyc-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });

    this.setNavigation({
      previousRoute: "/staff/users",
      icon: "mdi-account-lock-open",
      title: `${this.$tc("Views.sn-2")}: ${this.$route.params.id}`,
    });
    this.loader = false;
  }

  private get user() {
    return this.$store.getters["users/getUser"];
  }

  private get userKYC() {
    return this.$store.getters["kyc/getUserKYC"];
  }

  private async approveUserApplication() {
    this.loadingA = true;
    await this.$store
      .dispatch("users/kycValidation", {
        id: this.user.id,
        kycValidated: true,
      })
      .then(() => {
        this.checkUserDialog = true;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.kycv-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loadingA = false;
  }

  private async rejectUserApplication() {
    this.loadingR = true;
    await this.$store
      .dispatch("users/kycValidation", {
        id: this.user.id,
        kycValidated: false,
      })
      .then(() => {
        this.checkUserDialog = true;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.kycv-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loadingR = false;
  }

  private goToUsers() {
    this.navigate("/staff/users");
  }
}
